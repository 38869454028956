.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.animate-scroll {
  display: flex;
  animation: scroll 20s linear infinite;
}

@keyframes typing {
  0%, 100% { width: 0; }
  50% { width: 100%; }
}

.animate-typing {
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid;
  animation: typing 4s steps(40, end) infinite;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.login-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #f0f0f0;
}

body {
  background-color: #000;
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.timeline {
  position: relative;
  padding: 20px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  background: #fff;
  animation: moveLine 2s infinite;
}

@keyframes moveLine {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

.timeline-item {
  position: relative;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.timeline-item:nth-child(odd) {
  left: 0;
}

.timeline-item:nth-child(even) {
  left: 50%;
}

.timeline-item::before {
  content: '';
  position: absolute;
  top: 20px;
  right: -8px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
}

.timeline-item:nth-child(even)::before {
  left: -8px;
  right: auto;
}

.card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  transition: background 0.3s, backdrop-filter 0.3s;
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(20px);
}

.logo {
  transform: scale(0.7);
  margin-left: 10px;
}

.navbar .menu-container {
  width: 50%;
  margin: 0 auto;
}

.feature-container {
  display: flex;
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease-in-out;
  justify-content: center;
  width: 100%;
  border: none;
}

.feature-card {
  width: 300px;
  height: 400px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  perspective: 1000px;
  border-radius: 8px;
  flex-shrink: 0;
}

.feature-image {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.feature-text {
  position: absolute;
  width: 100%;
  color: white;
  z-index: 10;
}

.feature-story {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 0.9em;
  color: white;
  z-index: 10;
}

.feature-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 1.2em;
  color: white;
  z-index: 10;
}

.feature-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(75, 75, 75, 0.275);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: transform 0.6s;
}

.learn-more-button {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background-color: transparent;
  color: white;
  padding: 10px 24px;
  border: 2px solid white;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 500;
}

.learn-more-button:hover {
  background-color: white;
  color: black;
  transform: translateX(-50%) scale(1.05);
}

.learn-more-button:active {
  background-color: #f0f0f0;
  transform: translateX(-50%) scale(0.95);
}

.feature-card:hover .feature-image {
  transform: rotateY(180deg);
}

.feature-card:hover .feature-overlay {
  transform: rotateY(0);
}

.feature-section {
  position: relative;
  display: flex;
  align-items: center;
}

.feature-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.feature-card {
  scroll-snap-align: start;
  flex: 0 0 auto;
}

.swipe-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.swipe-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.swipe-button.left {
  left: 10px;
}

.swipe-button.right {
  right: 10px;
}

/* Ensure the flex container aligns items correctly */
.flex.items-center {
  display: flex;
  align-items: center;
}

.flex.items-center.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .feature-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 768px) {
  .feature-card {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 480px) {
  .feature-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* Remove any margin/padding that might affect alignment */
#overview {
  overflow: hidden;
  max-width: 1400px;
  width: 100%;
}

/* Ensure consistent spacing */
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

/* Add gap between cards */
.gap-4 {
  gap: 1rem;
}

/* Ensure smooth transitions */
.transition-transform {
  transition: transform 0.5s ease-in-out;
}

/* Navbar Styles */
.nav-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px 8px;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: rgba(255, 255, 255, 1);
}

.login-button {
  background-color: white;
  color: black;
  padding: 6px 16px;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.login-button:hover {
  opacity: 0.9;
}

.search-button {
  color: rgba(255, 255, 255, 0.7);
  padding: 6px;
  border-radius: 6px;
  transition: color 0.2s ease;
}

.search-button:hover {
  color: rgba(255, 255, 255, 1);
}

/* Clean navbar style */
nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Use flex-1 to create equal spacing */
.flex-1 {
  flex: 1 1 0%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .nav-link {
    padding: 0.75rem;
  }
}

/* Make the page content adjust for fixed navbar */
main {
  padding-top: 72px; /* Adjust based on navbar height */
}

/* Optional: Add blur effect for modern look */
.backdrop-blur-sm {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
